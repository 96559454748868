@charset "utf-8";

@import 'scss/_variables';
@import 'scss/_mixins';

.Footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: $grid-small $layout-page-padding-x-medium;

  @include bp-small {
    justify-content: space-between;
  }

  .Logo-icon {
    fill: $color-text-dark-secondary;
  }
}

.Footer-copyright {
  color: $color-text-dark-secondary;
  font-size: $font-size-body-xsmall;
}
