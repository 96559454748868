@charset "utf-8";

@import '../../scss/variables';
@import '../../scss//mixins';

.Contact {
  display: flex;
}

.Contact-link {
  width: $grid-large;
  height: $grid-large;
  border: 2px solid $color-text-dark-secondary;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  transition: all 0.5s ease;
  & > svg {
    display: block;
    width: 1.5rem;
    fill: $color-text-dark-secondary;
    transition: all 0.5s ease;
    &.icon-github {
      width: 1.6rem;
    }
    &.icon-email {
      width: 1.8rem;
    }
  }

  & + & {
    margin-left: $grid-xsmall;
  }

  @include hover-supported {
    border: 2px solid $color-text-dark-primary;
    transform: translateY(-2px);
    & > svg {
      fill: $color-text-dark-primary;
    }
  }

  @include keyboard-focus {
    box-shadow: 0 0 0 3px $color-background-primary,
      0 0 1px 5px $color-blue-medium;
  }
}
